export default {
  computed: {
    menuItems: function () {
      return [
        {
          tabLabel: "PLAN COORDINATION",
          index: "1",
          tabIcon: "columns",
          privilege:
            this.userPermissions.CanManageFulfilmentPlan ||
            this.userPermissions.ReadOnlyFulfilmentPlanAccess,
          subItems: [
            {
              label: "PLAN LIST",
              type: 2,
              index: "1-1",
              privilege:
                this.userPermissions.CanManageFulfilmentPlan ||
                this.userPermissions.ReadOnlyFulfilmentPlanAccess,
              subItems: this.fulfilmentPlanFiltersList,
            },
            {
              label: "CHANGES",
              type: 2,
              index: "1-2",
              privilege:
                this.userPermissions.CanManageFulfilmentPlan ||
                this.userPermissions.ReadOnlyFulfilmentPlanAccess,
              subItems: this.planChangesList,
            },
          ],
        },
        {
          tabLabel: "MANUFACTURING",
          index: "2",
          tabIcon: "industry",
          privilege:
            this.userPermissions.CanManageManufacturingCycles ||
            this.userPermissions.ReadOnlyManufacturingCycleAccess,
          subItems: [
            {
              label: "MANUFACTURING LIST",
              type: 2,
              index: "2-1",
              privilege:
                this.userPermissions.CanManageManufacturingCycles ||
                this.userPermissions.ReadOnlyManufacturingCycleAccess,
              subItems: this.manufacturingFiltersList,
            },
            {
              label: "COMBINED ORDERS",
              type: 2,
              index: "2-2",
              privilege:
                this.userPermissions.CanManageManufacturingCycles ||
                this.userPermissions.ReadOnlyManufacturingCycleAccess,
              subItems: this.combinedOrdersList,
            },
            {
              label: "CHANGES",
              type: 2,
              index: "2-3",
              privilege:
                this.userPermissions.CanManageFulfilmentPlan ||
                this.userPermissions.ReadOnlyFulfilmentPlanAccess,
              subItems: this.manufacturingChangesList,
            },
          ],
        },
        {
          tabLabel: "DESIGN",
          index: "3",
          tabIcon: "pencil-ruler",
          privilege:
            this.userPermissions.CanViewDesignSessions ||
            this.userPermissions.CanManageDesignSessions ||
            this.userPermissions.IsDesigner ||
            this.userPermissions.CanManageDesignSessionSettings,
          subItems: [
            {
              label: "MY SCHEDULE",
              url: "/CRT/Designer/Schedule",
              type: 1,
              index: "3-1",
              privilege:
                this.userPermissions.IsDesigner ||
                this.userPermissions.CanManageDesignSessions,
            },
            {
              label: "ALL SCHEDULES",
              url: "/CRT/Designer/AllSchedules",
              type: 1,
              index: "3-2",
              privilege:
                this.userPermissions.CanManageDesignSessions ||
                this.userPermissions.CanViewDesignSessions,
            },
            {
              label: "DESIGNER PROPERTIES",
              url: "/CRT/Designer/List",
              type: 1,
              index: "3-3",
              privilege: this.userPermissions.CanManageDesignSessions,
            },
            {
              label: "GENERAL SESSION PROPERTIES",
              url: "/CRT/GeneralSessionProperties/List",
              type: 1,
              index: "3-4",
              privilege: this.userPermissions.CanManageDesignSessionSettings,
            },
            {
              label: "SESSION PLANNER",
              url: "/CRT/Designer/SessionPlanner",
              type: 1,
              index: "3-5",
              privilege:
                this.userPermissions.CanManageDesignSessions ||
                this.userPermissions.CanViewDesignSessions,
            },
          ],
        },
        {
          tabLabel: "REPORTS",
          index: "4",
          tabIcon: "chart-pie",
          privilege: Object.keys(this.userPermissions)
            .filter((item) => item !== "CanManageUsers")
            .some((prop) => this.userPermissions[prop]),
          subItems: this.reportsList,
        },
        {
          tabLabel: "DOCUMENT DATA",
          index: "9",
          tabIcon: "file-alt",
          privilege:
            this.eDocPermissions.CanReadDocuments ||
            this.eDocPermissions.CanExportDocuments,
          subItems: [
            {
              label: "DOCUMENTS",
              type: 2,
              index: "9-1",
              privilege: this.eDocPermissions.CanReadDocuments,
              subItems: this.documentsFiltersList,
            },
            {
              label: "IMPORT DOCUMENT DATA",
              type: 2,
              index: "9-2",
              privilege: this.eDocPermissions.CanImportDocuments,
              subItems: [
                {
                  label: "IMPORT",
                  url: "/CRT/Documentation/DocumentImport",
                  type: 1,
                  index: "9-2-1",
                  privilege: this.eDocPermissions.CanImportDocuments,
                },
                {
                  label: "LIST",
                  url: "/CRT/Documentation/DocumentImport/List",
                  type: 1,
                  index: "9-2-2",
                  privilege: this.eDocPermissions.CanImportDocuments,
                },
              ],
            },
            {
              label: "EXPORT DOCUMENTS",
              url: "/CRT/Documentation/Document/Export",
              type: 1,
              index: "9-3",
              privilege: this.eDocPermissions.CanExportDocuments,
            },
          ],
        },
        {
          tabLabel: "PRODUCT DATA",
          index: "10",
          tabIcon: "box",
          privilege:
            this.eDocPermissions.CanReadProducts ||
            this.eDocPermissions.CanImportProducts ||
            this.eDocPermissions.CanExportProducts,
          subItems: [
            {
              label: "PRODUCTS",
              type: 2,
              index: "10-1",
              privilege: this.eDocPermissions.CanReadProducts,
              subItems: this.productsFiltersList,
            },
            {
              label: "IMPORT PRODUCT DATA",
              type: 2,
              index: "10-2",
              privilege: this.eDocPermissions.CanImportProducts,
              subItems: [
                {
                  label: "IMPORT",
                  url: "/CRT/Documentation/ProductImport",
                  type: 1,
                  index: "10-2-1",
                  privilege: this.eDocPermissions.CanImportProducts,
                },
                {
                  label: "LIST",
                  url: "/CRT/Documentation/ProductImport/List",
                  type: 1,
                  index: "10-2-2",
                  privilege: this.eDocPermissions.CanImportProducts,
                },
              ],
            },
            {
              label: "EXPORT PRODUCTS",
              url: "/CRT/Documentation/Product/Export",
              type: 1,
              index: "10-3",
              privilege: this.eDocPermissions.CanExportProducts,
            },
          ],
        },
        {
          tabLabel: "KEYCODE",
          index: "11",
          tabIcon: "key",
          privilege:
            this.eDocPermissions.CanReadProducts ||
            this.eDocPermissions.CanExportProducts,
          subItems: [
            {
              label: "LIST",
              url: "/CRT/Documentation/KeyCode",
              type: 1,
              index: "11-1",
              privilege: this.eDocPermissions.CanReadProducts,
            },
            {
              label: "EXPORT",
              url: "/CRT/Documentation/KeyCode/Export",
              type: 1,
              index: "11-2",
              privilege: this.eDocPermissions.CanExportProducts,
            },
          ],
        },
      ];
    },
    configurationMenuItems: function () {
      return [
        {
          label: "USERS",
          index: "5-1",
          url: "/CRT/Users",
          type: 1,
          privilege: this.userPermissions.CanManageUsers,
        },
        {
          label: "REPORT DEFINITIONS",
          index: "5-2",
          url: "/CRT/Reports",
          type: 1,
          privilege: this.userPermissions.CanManageReportDefinitions,
        },
        {
          label: "SURGEON PREFERENCES",
          index: "5-3",
          url: "/CRT/SurgeonPreferences",
          type: 1,
          privilege: this.userPermissions.CanManageSurgeonPreferences,
        },
        {
          label: "DISTRIBUTION CENTERS",
          index: "5-4",
          url: "/CRT/DistributionCenters",
          type: 1,
          privilege: this.userPermissions.CanManageMappings,
        },
        {
          label: "LEAD TIMES",
          index: "5-5",
          type: 2,
          privilege: this.userPermissions.CanManageMilestoneLeadTimes,
          subItems: [
            {
              label: "MILESTONE LEAD TIMES",
              url: "/CRT/MilestoneLeadTime",
              index: "5-5-1",
              privilege: this.userPermissions.CanManageMilestoneLeadTimes,
            },
            {
              label: "LEAD TIME MODIFICATIONS",
              url: "/CRT/LeadTimeModifications",
              index: "5-5-2",
              privilege: this.userPermissions.CanManageMilestoneLeadTimes,
            },
          ],
        },

        {
          label: "PRODUCT",
          index: "5-6",
          type: 2,
          privilege:
            this.userPermissions.CanManageProductTypes ||
            this.userPermissions.CanManageMappings,
          subItems: [
            {
              label: "PRODUCTS LIST",
              url: "/CRT/Product",
              index: "5-6-1",
              privilege: this.userPermissions.CanManageProductTypes,
            },
            {
              label: "PRODUCT TYPES",
              url: "/CRT/ProductType",
              index: "5-6-2",
              privilege: this.userPermissions.CanManageProductTypes,
            },
            {
              label: "PRODUCT TYPE MAPPINGS",
              url: "/CRT/ProductTypeMapping",
              index: "5-6-3",
              privilege: this.userPermissions.CanManageMappings,
            },
          ],
        },
        {
          label: "MANUFACTURING",
          index: "5-7",
          type: 2,
          privilege:
            this.userPermissions.CanManageWorkflows ||
            this.userPermissions.CanManageWorkflowStepEfforts ||
            this.userPermissions.CanFulfilmentPlanAdministrate,
          subItems: [
            {
              label: "JOB STEPS",
              url: "/CRT/JobStep",
              index: "5-7-1",
              privilege: this.userPermissions.CanManageWorkflows,
            },
            {
              label: "WORKFLOWS",
              url: "/CRT/Workflow/List",
              index: "5-7-2",
              privilege: this.userPermissions.CanManageWorkflows,
            },
            {
              label: "WORKFLOW STEP EFFORTS",
              url: "/CRT/WorkflowStepEfforts",
              index: "5-7-3",
              privilege: this.userPermissions.CanManageWorkflowStepEfforts,
            },
            {
              label: "MANUFACTURING CYCLE DEFINITIONS",
              url: "/CRT/ManufacturingCycleDefinition/List",
              index: "5-7-4",
              privilege: this.userPermissions.CanManageWorkflows,
            },
            {
              label: "MANUFACTURING LOCATIONS",
              url: "/CRT/ManufacturingLocations",
              index: "5-7-5",
              privilege: this.userPermissions.CanFulfilmentPlanAdministrate,
            },
          ],
        },
        {
          label: "IMPORT",
          index: "5-8",
          type: 2,
          privilege: this.userPermissions.CanManageImportSettings,
          subItems: [
            {
              label: "IMPORT REQUESTS",
              url: "/CRT/ImportRequest/ImportFile",
              index: "5-8-1",
              privilege: this.userPermissions.CanManageImportSettings,
            },
            {
              label: "LIST OF IMPORTS",
              url: "/CRT/ImportRequest/List",
              index: "5-8-2",
              privilege: this.userPermissions.CanManageImportSettings,
            },
            {
              label: "IMPORT LOG",
              url: "/CRT/ImportRequest/Log",
              index: "5-8-3",
              privilege: this.userPermissions.CanManageImportSettings,
            },
            {
              label: "TRACKING INFORMATION",
              url: "/CRT/TrackingInformation",
              index: "5-8-4",
              privilege: this.userPermissions.CanManageImportSettings,
            },
          ],
        },
        {
          label: "CALENDAR",
          index: "5-9",
          type: 2,
          privilege: this.userPermissions.CanManageLocalCalendar,
          subItems: [
            {
              label: "LOCAL CALENDAR",
              url: "/CRT/LocalCalendar",
              index: "5-9-1",
              privilege: this.userPermissions.CanManageLocalCalendar,
            },
            {
              label: "MANAGE CALENDARS",
              url: "/CRT/ManageCalendars",
              index: "5-9-2",
              privilege: this.userPermissions.CanManageLocalCalendar,
            },
          ],
        },
        {
          label: "eDOC",
          index: "5-10",
          type: 2,
          privilege: this.eDocPermissions.CanManageEDocSystemConfiguration,
          subItems: [
            {
              label: "DOCUMENT TYPES",
              url: "/CRT/Documentation/DocumentType",
              index: "5-10-1",
              privilege: this.eDocPermissions.CanManageEDocSystemConfiguration,
            },
            {
              label: "DIVISIONS",
              url: "/CRT/Documentation/Division",
              index: "5-10-2",
              privilege: this.eDocPermissions.CanManageEDocSystemConfiguration,
            },
            {
              label: "PORTALS",
              url: "/CRT/Documentation/Portal",
              index: "5-10-3",
              privilege: this.eDocPermissions.CanManageEDocSystemConfiguration,
            },
            {
              label: "PRODUCT FAMILIES",
              url: "/CRT/Documentation/ProductFamily",
              index: "5-10-4",
              privilege: this.eDocPermissions.CanManageEDocSystemConfiguration,
            },
            {
              label: "LANGUAGES",
              url: "/CRT/Documentation/Language",
              index: "5-10-5",
              privilege: this.eDocPermissions.CanManageEDocSystemConfiguration,
            },
            {
              label: "COUNTRIES",
              url: "/CRT/Documentation/Country",
              index: "5-10-6",
              privilege: this.eDocPermissions.CanManageEDocSystemConfiguration,
            },
            //{ label: 'REGIONS', url: '/CRT/Documentation/Region', index: '5-10-7', privilege: this.eDocPermissions.CanManageEDocSystemConfiguration },
          ],
        },
      ];
    },
    userMenuItems: function () {
      return [
        {
          label: "NOTIFICATIONS SETTINGS",
          index: "6-2",
          url: "/CRT/Notification/NotificationsSettings",
          type: 1,
          privilege: this.hasAnyCrtPermission,
        },
        {
          label: "FILTER MANAGEMENT",
          index: "6-2",
          url: "/CRT/FilterManagement",
          type: 1,
          privilege: true,
        },
      ];
    },
  },
};
